import React, { useContext, useEffect } from "react"
import { AuthContext } from "../context/auth"
import { convertToBgImage } from "gbimage-bridge"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImage from "gatsby-background-image"
import VolverLogin from "../components/VolverLogin"
import "../css/background-image.css"
import { Grid, Hidden } from "@material-ui/core"
import { injectIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import Botones from "../components/Botones"
import BotonesMovil from "../components/BotonesMovil"
import Carteles from "../components/Carteles"
import CartelesMovil from "../components/CartelesMovil"
import ModalPresencialInterno from "../components/ModalPresencialInterno"
import Videos from "../components/Videos"

const IndexPage = ({ intl, data }) => {
  const { user } = useContext(AuthContext)
  const { nombre, imagen_fondo, inicio, carteles, alert_resolucion } =
    data.strapiInicio
  const currentLocale = intl.locale

  useEffect(() => {
    const width = window.innerWidth
    if (width < 720) {
      alert(
        currentLocale === "en"
          ? alert_resolucion.en
          : currentLocale === "pt"
          ? alert_resolucion.pt
          : alert_resolucion.es
      )
    }
  }, [])

  const image = getImage(imagen_fondo.localFile)
  const bgImage = convertToBgImage(image)

  // test horas
  /* console.log("timezone", Intl.DateTimeFormat().resolvedOptions().timeZone)
function convertTZ(date, tzString) {
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
} */

  // usage: Asia/Jakarta is GMT+7
  //convertTZ("2012/04/20 10:10:30 +0000", "Asia/Jakarta") // Tue Apr 20 2012 17:10:30 GMT+0700 (Western Indonesia Time)

  // Resulting value is regular Date() object
  //const convertedDate = convertTZ("2012/04/20 10:10:30 +0000", "Asia/Jakarta")
  //convertedDate.getHours(); // 17

  // ver como las traigo de strapi y como se convierten a la mia, ver donde defino
  //en que timezone las muestro o viene
  // Bonus: You can also put Date object to first arg
  /* const date = new Date()
convertTZ(date, "Asia/Jakarta") // current date-time in jakarta.
console.log("convertida Asia/Jakarta", convertTZ(date, "Asia/Jakarta"))
console.log("convertida a la que esta viendo", convertTZ(date, Intl.DateTimeFormat().resolvedOptions().timeZone))
 */

  return (
    <>
      {user != null ? (
        <Layout>
          <SEO
            title={
              currentLocale === "en"
                ? nombre.en
                : currentLocale === "pt"
                ? nombre.pt
                : nombre.es
            }
          />
          <Hidden xsDown>
            <BackgroundImage
              Tag="section"
              {...bgImage}
              preserveStackingContext
              style={{ position: "fixed", top: 0 }}
              className="backimage"
            >
              <div
                style={{
                  position: "absolute",
                  left: "0%",
                  bottom: "0%",
                  width: "100%",
                }}
              >
                <ModalPresencialInterno />
              </div>
              {carteles.map(cartel => (
                <div
                  key={cartel.id}
                  style={{
                    position: "absolute",
                    left: cartel.left,
                    top: cartel.top,
                    width: cartel.width,
                  }}
                >
                  <Carteles cartel={cartel} />
                </div>
              ))}
              {inicio.map(items => (
                <div key={items.id}>
                  {items.strapi_component === "botones.boton" ? (
                    <div
                      style={{
                        position: "absolute",
                        left: items.left,
                        top: items.top,
                        width: items.width,
                      }}
                    >
                      <Botones boton={items} />
                    </div>
                  ) : items.strapi_component === "videos.video" ? (
                    <div
                      style={{
                        position: "absolute",
                        left: items.left,
                        top: items.top,
                        width: items.width,
                        height: items.height,
                      }}
                    >
                      <Videos video={items} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </BackgroundImage>
          </Hidden>

          {/*************** INICIO DE VERSION PARA CELULAR ************************/}
          <Hidden smUp>
            <Grid
              container
              direction="column"
              alignItems="center"
              style={{
                backgroundColor: `${data.configuracion.color_movil}`,
              }}
            >
              {data.configuracion.logo_movil !== null &&
              data.configuracion.logo_movil.localFile !== null ? (
                <GatsbyImage
                  image={
                    data.configuracion.logo_movil.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  alt="cabecera"
                />
              ) : (
                ""
              )}
              {inicio.map(items => (
                <div key={items.id}>
                  {items.strapi_component === "botones.boton" ? (
                    <Grid item xs={12} style={{ marginTop: 20, width: "100%" }}>
                      <BotonesMovil boton={items} />
                    </Grid>
                  ) : items.strapi_component === "videos.video" ? (
                    <Grid item xs={12}>
                      <Videos video={items} />
                    </Grid>
                  ) : (
                    ""
                  )}
                </div>
              ))}
              <div>&nbsp;</div>
              {carteles.map(cartel => (
                <Grid item xs={12} key={cartel.id}>
                  <CartelesMovil cartel={cartel} />
                </Grid>
              ))}
              <div>&nbsp;</div>
              <ModalPresencialInterno />
              {/*               {inicio.map(items => (
                <div key={items.id}>
                  {items.strapi_component === "botones.boton" ? (
                    <Grid item xs={12} style={{ marginTop: 20, width: "100%" }}>
                      <BotonesMovil boton={items} />
                    </Grid>
                  ) : items.strapi_component === "videos.video" ? (
                    <Grid item xs={12}>
                      <Videos video={items} />
                    </Grid>
                  ) : (
                    ""
                  )}
                </div>
              ))} */}
              <div>&nbsp;</div>
            </Grid>
          </Hidden>
        </Layout>
      ) : (
        <VolverLogin />
      )}
    </>
  )
}

export default injectIntl(IndexPage)

export const query = graphql`
  query {
    configuracion: strapiConfiguracion {
      color_movil
      logo_movil {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    strapiInicio {
      alert_resolucion {
        es
        en
        pt
      }
      nombre {
        es
        en
        pt
      }
      imagen_fondo {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      inicio
      carteles {
        ampliar_cartel
        mostrar
        mostrar_celular
        nombre
        proporcion
        left
        id
        tiempo_slideshow
        top
        width
        marco_size
        color_fondo_marco
        slideshow_imagenes {
          id
          imagen {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  width: 1000
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          imagen_link
          imagen_link_tipo
        }
      }
    }
  }
`
